<template>
  <div style="display: inline-block">
    <b-button
      variant="success2"
      class="mr-2 confirm-delivery"
      :disabled="isCanSubmit || disabled"
      @click="onShowModal()"
      >Mass Stored
      <i class="far fa-plus-square"></i>
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Store Confirmation"
      id="store_confirmation"
      ref="modal"
      @hide="modelHide"
    >
      <b-overlay :show="modalloading">
        <p>
          Are you sure to finish the review and change the review status to "Stored"?
        </p>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

export default {
  name: 'MassStoreArticleReview',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      submitError: false,
      options: {
        logistics: []
      }
    };
  },
  props: ['ids', 'selectData', 'initDataFn', 'disabled'],
  components: {},
  methods: {
    onShowModal() {
      this.showModal = true;
      this.submitDisabled = false;
      this.modalloading = false;
    },
    submit() {
      var data = {
        article_review_ids: this.ids
      };
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.articleReview
        .articleReviewStoreMass(data)
        .then((data) => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.submitError = false;
          this.initDataFn();
        })
        .catch((error) => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    initialTime(obj, name) {
      obj[name] = null;
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    init() {}
  },

  computed: {
    isCanSubmit() {
      if (this.selectData.length == 0) {
        return true;
      } else {
        let confirmArr = [],
          orderArr = [],
          passArr = [];
        this.selectData.map((item) => {
          if (item.status === 'Received') {
            confirmArr.push(item);
            if (
              orderArr.indexOf(item.order_information.cps_order_number) ===
                -1 &&
              item.order_information.cps_order_number.substr(0, 2) === '16'
            ) {
              orderArr.push(item.order_information.cps_order_number);
            }
            if (item.order_information.cps_order_number.substr(0, 2) !== '16') {
              passArr.push(item.order_information.cps_order_number);
            }
          }
        });
        console.log(confirmArr, orderArr, passArr);
        if (confirmArr.length != this.selectData.length) return true;
        if (orderArr.length == 0 || orderArr.length > 1) return true;
        if (passArr.length > 0) return true;
      }
      return false;
    }
  },
  watch: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
#void_target .modal-body {
  padding-bottom: 150px;
}
</style>
